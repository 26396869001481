<script setup>
const props = defineProps({
  leadGenForm: {
    type: Object
  },
  leadTypeID: {
    type: String,
    required: true
  },
  sharedContent: {
    type: Boolean,
    default: false  
  },
  tags: {
    type: Array,
    default: null
  }
})
 
 

const config = useRuntimeConfig()

const cookiesArr = ['utm_source', 'utm_medium', 'utm_term', 'utm_campaign', 'utm_content', 'utmSiteVisitDate', 'User_Agent', 'DestinationUrl']
// Model variables and utility variables
let leadGenFields,
  formSent = ref(false),
  printedBrochure = ref(false), 
  formData = ref(null), 
  inputFirstName = ref(''), 
  inputEmail = ref(''), 
  inputPostalCode = ref(''),
  inputPhone = ref(''), 
  errorFound = ref(false), 
  errMsg = ref(''), 
  nameError = ref(false), 
  emailError = ref(false), 
  postalcodeError = ref(false),
  phoneError = ref(false),
  childrenCount = ref(1),
  childrenNames = ref(['','','','','']),
  childrenAges = ref(['','','','','']),
  address1 = ref(''),
  address2 = ref(''),
  city = ref(''),
  state = ref('')


const { getLeadGenLocal, setLeadGenLocal, leadGenData } = useLeadGenData();
const { data: leadGen } = await leadGenData();
leadGenFields = leadGen.value.leadGenFields
const {leadGenFullWidthData} = useLeadGenData()
const {data: shared, error} = await leadGenFullWidthData();
// Array of ages and birth years
let ages = []
let currentYear = new Date
for (let i = 7; i < 19; i++) {
  ages.push({ value: (parseInt(currentYear.getFullYear()) - i), label: i })
}

defineExpose({
  submitHandler
})

let learningStylesLabels = leadGenFields.learningStylesCheckboxes

// Form submit handler
async function submitHandler() {

  const formObject = { leadTypeID: props.leadTypeID }
  let selectedLearningStyles = [], defaultLearningStyles = []
  // Creation of the JSON object that will be sent to the API endpoint
  for (let element of formData.value.elements) {
    // Element must have a name, a value and not be a multi-dropdown checkbox
    if (element.name !== '' && element.value !== '' && !element.classList.contains('multi-checkbox')) {
      // If is a checkbox we make sure they get sent as an array
      if (element.type === 'checkbox') {
        if (element.name === 'learningStyleTypeIDs') {
          defaultLearningStyles.push(element.value)

          if (element.checked == 1) {
            selectedLearningStyles.push(element.value)
          }

        } else {
          !Array.isArray(formObject[element.name]) ? formObject[element.name] = [element.value] : formObject[element.name].push(element.value)
        }
      } else {
        if (element.name === 'studentBirthYears') {
          // Splitting the string separated by commas into an arrary
          formObject[element.name] = element.value.split(',')
        } else {
          // Any other form element will be sent as is
          formObject[element.name] = element.value
        }
      }
    }
  }

  if (selectedLearningStyles.length > 0) {
    formObject['learningStyleTypeIDs'] = selectedLearningStyles
  } else if (defaultLearningStyles.length > 0 ) {
    formObject['learningStyleTypeIDs'] = defaultLearningStyles
  } else {
    // This is just in case they don't select the field on the page to show so it has default values.
    formObject['learningStyleTypeIDs'] = [405000, 405001, 405002, 405003]
  }

  if (printedBrochure.value) {
    formObject.sendPrintedInformation = true
    formObject.address1 = address1.value
    formObject.address2 = address2.value
    formObject.city = city.value
    formObject.state = state.value
  } else {
    formObject.sendPrintedInformation = false
    formObject.address1 = ""
    formObject.address2 = ""
    formObject.city = ""
    formObject.state = ""
  }

  // Adjust phone number
  formObject['phoneNumber'] = { 'countryCode': '', 'areaCode': '', 'number': formObject['phoneNumber'], 'extension': '' }
  
  // Create object for students Name/Age
  formObject['students'] = cleanChildren(formObject)

  // Last elements to send
  formObject.sourcePageUrl = window.location.href

  function isObject(objValue) {
    return objValue && typeof objValue === 'object' && objValue.constructor === Object;
  }
  
  if ((props.leadGenForm && (props.leadGenForm.tagsArray !== null && props.leadGenForm.tagsArray.length > 0)) || (props.tags !== null && props.tags.length > 0)) { 
    const tagsArray = props.tags ? props.tags : props.leadGenForm.tagsArray
    let tags = []
    if (isObject(tagsArray)) {
      for (let i = 0; i < tagsArray.length; i++) {
        tags.push(tagsArray[i].tag)
      }
    } else {
      for (let i = 0; i < tagsArray.length; i++) {
        if (isObject(tagsArray[i])) {
          tags.push(tagsArray[i].tag)
        } else {
          tags.push(tagsArray[i])
        }
      }
    }
    formObject['tagsArray'] = tags
  }

  if (props.leadGenForm && (props.leadGenForm.locationName !== null || props.leadGenForm.locationName !== '')) {
    formObject['locationName'] = props.leadGenForm.locationName
  }

  if (props.leadGenForm && props.leadGenForm.locationId !== null) {
    formObject['locationId'] = props.leadGenForm.locationId
  }

  // Update form object with cookies parameters
  for (let cookieName in cookiesArr) {
    let cookie = useCookie(cookieName)
    if (!!cookie && cookie.value != '') {
      formObject[cookieName] = cookie.value
    }
  }

  // Transform the object into a JSON
  const formJSON = JSON.stringify(formObject)
  const hashedData = hashObject(formObject); 

  dataLayer.push({
    'event': 'formSubmitted',
    'leadsUserData': hashedData
  });
  // Sending a record lead
  const nuxtApp = useNuxtApp() // This gives me a warning
  let apiResponse = await nuxtApp.postData(config.public.pulseAPILeadGenEndpoint, formJSON)

  if (apiResponse.message == 'The request is invalid.' || apiResponse.message == 'An error has occurred.') {
    errMsg.value = apiResponse.message
  } else {
    // Needs functionality to show up social share component
    formSent.value = true
  }

}

function cleanChildren(formObject){
  // Initial array for children
  let children = []
  // If there is any children
  for (let i=0; i < childrenCount.value; i++){
    let nameKey = 'child_name_' + (i + 1), ageKey = 'child_age_' + (i + 1)
    // Add the object into the array of children
    children[i] = {
      'studentFirstName': formObject[nameKey],
      'studentBirthYear': formObject[ageKey]
    }
    delete formObject[nameKey]
    delete formObject[ageKey]
  }
  return children
}

function checkErrors() {
  if (nameError.value || emailError.value || postalcodeError.value) {
    errorFound.value = true
  } else if (!nameError.value && !emailError.value && !postalcodeError.value) {
    errorFound.value = false
  }
}

function addChild(){
  if (childrenCount.value < 5) {
    childrenCount.value = childrenCount.value + 1
  }
}

function removeChild() {
  if (childrenCount.value > 1) {
    childrenAges.value[childrenCount.value] = ''
    childrenNames.value[childrenCount.value] = ''
    childrenCount.value = childrenCount.value - 1
  }
}

onMounted(() => {
  const formSections = document.getElementsByClassName('lead-gen-form-component')
  for (let i = 0; i < formSections.length; i++) {
    const section = formSections[i]
    const fieldsets = section.getElementsByTagName('fieldset');
    fieldsets[0].removeAttribute('disabled')
  } 
  
})

const formComponent = {
  defaultMessage: props.leadGenForm  ? props.leadGenForm.defaultMessage : shared.value.mainLeadGen.defaultMessage,
  customMessage: props.leadGenForm && props.leadGenForm.customMessage ? props.leadGenForm.customMessage : shared.value.mainLeadGen.customMessage,
  formTitle: props.leadGenForm && props.leadGenForm.formTitle ? props.leadGenForm.formTitle : (props.leadGenForm && props.leadGenForm.sharedContent ? shared.value.mainLeadGen.formTitle : null),
  formSubtitle: props.leadGenForm && props.leadGenForm.formSubtitle ? props.leadGenForm.formSubtitle : (props.leadGenForm && props.leadGenForm.sharedContent ? shared.value.mainLeadGen.formSubtitle : null),
  name: props.leadGenForm && props.leadGenForm.name ? props.leadGenForm.name : shared.value.mainLeadGen.name,
  email: props.leadGenForm && props.leadGenForm.email ? props.leadGenForm.email : shared.value.mainLeadGen.email,
  zipcode: props.leadGenForm && props.leadGenForm.zipcode ? props.leadGenForm.zipcode : shared.value.mainLeadGen.zipcode,
  phone: props.leadGenForm && props.leadGenForm.phone ? props.leadGenForm.phone : shared.value.mainLeadGen.phone,
  childInformation: props.leadGenForm && props.leadGenForm.childInformation ? props.leadGenForm.childInformation : shared.value.mainLeadGen.childInformation,
  learningFormats: props.leadGenForm && !props.sharedContent ? props.leadGenForm.learningFormats : (shared.value.mainLeadGen.learningFormats ? leadGen.value.leadGenFields.learningStylesCheckboxes : false),
  printedBrochure: props.leadGenForm && props.leadGenForm.printedBrochure ? props.leadGenForm.printedBrochure : shared.value.mainLeadGen.printedBrochure,
  formDisclaimer: props.leadGenForm && props.leadGenForm.formDisclaimer ? props.leadGenForm.formDisclaimer : shared.value.mainLeadGen.formDisclaimer,
  submitButtonText: props.leadGenForm && props.leadGenForm.submitButtonText ? props.leadGenForm.submitButtonText : shared.value.mainLeadGen.submitButtonText,
  subtitlePrintedBrochure: props.leadGenForm && props.leadGenForm.subtitlePrintedBrochure ? props.leadGenForm.subtitlePrintedBrochure : shared.value.mainLeadGen.subtitlePrintedBrochure,
  checkboxPrintedBrochure: props.leadGenForm && props.leadGenForm.checkboxPrintedBrochure ? props.leadGenForm.checkboxPrintedBrochure : shared.value.mainLeadGen.checkboxPrintedBrochure,
}

</script>
<template>
  <section id="lead-gen-form" class="lead-gen-form-component">
    <div class="container">
      <div class="row">
        <div class="col-12 social-share-container" v-if="formSent">
          <UISocialShare :title="''" :division="''" v-if="formComponent.defaultMessage"/>
          <GenericContent :data="{content : formComponent.customMessage}" v-else></GenericContent>
        </div>
        <div class="col-12 lead-gen-form-inner-section" v-else>
          <div class="lead-gen-form-container">
            <UIHeading :titleComponent="formComponent.formTitle" :subtitleComponent="formComponent.formSubtitle"
              :titleClass="'lead-gen-form-title'" :subtitleClass="'lead-gen-form-subtitle'" />
            <form class="lead-gen-form" ref="formData" @submit.prevent="submitHandler">
              <fieldset disabled="disabled">
                <div class="row">
                  <div class="col-12" v-if="!!formComponent.name">
                    <div class="form-floating input-container">
                      <input type="text" id="firstName" class="form-control form-input" name="firstName"  v-model="inputFirstName" :placeholder="leadGenFields.namePlaceholderText" @focusout="checkErrors()" required>
                      <label for="firstName" v-html="nameError ? leadGenFields.nameErrorMessage : leadGenFields.namePlaceholderText"></label>
                
                    </div>
                  </div>
                  <div class="col-12" v-if="!!formComponent.email">
                    <div class="form-floating input-container">
                      <input type="email" id="emailAddress" class="form-control form-input" name="emailAddress"  v-model="inputEmail" :placeholder="leadGenFields.emailFields.emailPlaceholderText" @focusout="checkErrors()" required>
                      <label for="emailAddress" v-html="emailError ? leadGenFields.emailFields.emailErrorMessage : leadGenFields.emailFields.emailPlaceholderText"></label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6" v-if="!!formComponent.zipcode">
                    <div class="form-floating input-container">
                      <input type="text" id="postalCode" class="form-control form-input" name="postalCode"  v-model="inputPostalCode" :placeholder="leadGenFields.zipcodePlaceholderText" @focusout="checkErrors()">
                      <label for="postalCode" v-html="postalcodeError ? leadGenFields.zipcodeErrorMessage : leadGenFields.zipcodePlaceholderText"></label>
                    </div>
                  </div>
                  <div class="col-6" v-if="!!formComponent.phone">
                    <div class="form-floating input-container">
                      <input type="text" id="phoneNumber" class="form-control form-input" name="phoneNumber"  v-model="inputPhone" :placeholder="leadGenFields.phonePlaceholderText" @focusout="checkErrors()">
                      <label for="phoneNumber" v-html="phoneError ? leadGenFields.phoneErrorMessage : leadGenFields.phonePlaceholderText"></label>
                    </div>
                  </div>
                </div>
                <div class="form-heading checkbox-heading" v-if="!!formComponent.childInformation">
                  <div class="checkbox-title">Child Information</div>
                </div>

                <div class="row child-list-container" v-if="!!formComponent.childInformation" v-for="(child, index) in childrenCount">
                  <div class="col-12 col-md-8">
                    <div class="form-floating input-container">
                      <input type="text" :id="'child_name_' + child" class="form-control form-input" :name="'child_name_' + child"  
                        v-model="childrenNames[child-1]" placeholder="First Name" @focusout="checkErrors()">
                      <label :for="'child_name_' + child" v-html="'First Name'"></label>
                    </div>                    
                  </div>
                  <div class="col-8 col-md-3">
                    <div class="form-floating input-container select-container">
                      <select :id="'child_age_' + child" class="form-control form-input" :name="'child_age_' + child"  
                        v-model="childrenAges[child-1]" placeholder="Age" @focusout="checkErrors()">
                        <option v-for="(age, i) in ages" :value="age.value" :selected="i == 0 ? true : false">{{age.label}}</option>
                      </select>
                      <div class="icon-chevron-down"></div>
                      <label :for="'child_age_' + child" v-html="'Age'"></label>
                    </div>
                  </div>
                  <div v-if="index !== 0" class="col-4 col-md-1">
                    <div class="form-floating input-container close-container">
                      <a @click="removeChild()" class="icon-close"></a>
                    </div>
                  </div>
                </div>
                <div class="row add-child" v-if="!!formComponent.childInformation">
                  <div class="col-12">
                    <div class="form-floating input-container">
                      <a :class="'btn btn-secondary' + (childrenCount == 5 ? ' disabled' : '')" @click="addChild()">+ Add child</a>
                    </div>
                  </div>
                </div>

                <div class="form-heading checkbox-heading" v-if="!!formComponent.learningFormats">
                  <div class="checkbox-title">{{ leadGenFields.titleProgramType }}</div>
                  <div class="checkbox-subtitle">{{ leadGenFields.subTitleProgramType }}</div>
                </div>
                <div class="checkbox-group row" v-if="!!formComponent.learningFormats">
                  <div class="checkbox-container col-12"
                    v-if="!!learningStylesLabels.OnCampusSummerProgramsLabel">
                    <input type="checkbox" name="learningStyleTypeIDs" class="form-checkbox" value="405000">
                    <span class="checkmark form-checkmark"></span>
                    <span class="checkbox-label"> {{ learningStylesLabels.OnCampusSummerProgramsLabel }}</span>
                  </div>
                  <div class="checkbox-container col-12" v-if="!!learningStylesLabels.OnlineCampsClassesLabel">
                    <input type="checkbox" name="learningStyleTypeIDs" class="form-checkbox" value="405001">
                    <span class="checkmark form-checkmark"></span>
                    <span class="checkbox-label"> {{ learningStylesLabels.OnlineCampsClassesLabel }}</span>
                  </div>
                  <div class="checkbox-container col-12" v-if="!!learningStylesLabels.SmallGroupOnlineLabel">
                    <input type="checkbox" name="learningStyleTypeIDs" class="form-checkbox" value="405002">
                    <span class="checkmark form-checkmark"></span>
                    <span class="checkbox-label"> {{ learningStylesLabels.SmallGroupOnlineLabel }}</span>
                  </div>
                  <div class="checkbox-container col-12"
                    v-if="!!learningStylesLabels.OneOnOnePrivateLessonsLabel">
                    <input type="checkbox" name="learningStyleTypeIDs" class="form-checkbox" value="405003">
                    <span class="checkmark form-checkmark"></span>
                    <span class="checkbox-label"> {{ learningStylesLabels.OneOnOnePrivateLessonsLabel }}</span>
                  </div>
                </div>
                <div v-if="!!formComponent.printedBrochure" class="form-heading checkbox-heading">
                  <div class="checkbox-title">{{ formComponent.subtitlePrintedBrochure }}</div>
                </div>
                <div :class="'checkbox-group row' + (printedBrochure ? '' : ' mb-4')" v-if="!!formComponent.printedBrochure">
                  <div class="checkbox-container col-12">
                    <input type="checkbox" name="sendPrintedInformation" class="form-checkbox" v-model="printedBrochure">
                    <span class="checkmark form-checkmark"></span>
                    <span class="checkbox-label"> {{ formComponent.checkboxPrintedBrochure }}</span>
                  </div>
                </div>

                <div class="printed-brochure-group row" v-if="printedBrochure">
                  <div class="col-12">
                    <div class="form-floating input-container">
                      <input type="text" id="address1" class="form-control form-input" name="address1"  v-model="address1" :placeholder="leadGenFields.mailingAddressPlaceholderText" @focusout="checkErrors()" required>
                      <label for="address1" v-html="leadGenFields.mailingAddressErrorText ? leadGenFields.mailingAddressErrorText : leadGenFields.mailingAddressPlaceholderText"></label>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="form-floating input-container">
                      <input type="text" id="address2" class="form-control form-input" name="address2" v-model="address2" :placeholder="leadGenFields.address2PlaceholderText" @focusout="checkErrors()">
                      <label for="address2" v-html="leadGenFields.address2ErrorText ? leadGenFields.address2ErrorText : leadGenFields.address2PlaceholderText"></label>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="form-floating input-container">
                      <input type="text" id="city" class="form-control form-input" name="city"  v-model="city" :placeholder="leadGenFields.cityPlaceholderText" @focusout="checkErrors()" required>
                      <label for="city" v-html="leadGenFields.cityErrorText ? leadGenFields.cityErrorText : leadGenFields.cityPlaceholderText"></label>
                    </div>
                  </div>

                  <div class="col-12 col-md-6">
                    <div class="form-floating input-container">
                      <input type="text" id="state" class="form-control form-input" name="state"  v-model="state" :placeholder="leadGenFields.statePlaceholderText" @focusout="checkErrors()" required>
                      <label for="state" v-html="leadGenFields.stateErrorText ? leadGenFields.stateErrorText : leadGenFields.statePlaceholderText"></label>
                    </div>
                  </div>

                  <div class="col-12 col-md-6">
                    <div class="form-floating input-container">
                      <input type="text" id="postalCode2" class="form-control form-input" name="postalCode2"  v-model="inputPostalCode" :placeholder="leadGenFields.zipcodePlaceholderText" @focusout="checkErrors()" required>
                      <label for="postalCode2" v-html="postalcodeError ? leadGenFields.zipcodeErrorMessage : leadGenFields.zipcodePlaceholderText"></label>
                    </div>
                  </div>
                </div>

                <div class="form-extra-disclaimer" v-if="!!formComponent.formDisclaimer && formComponent.formDisclaimer !== ''" v-html="formComponent.formDisclaimer"></div>

                <div class="form-error-message" v-html="errMsg" v-if="!!errMsg"></div>
                <div class="submit-button">
                  <button :class="'submit-btn btn display btn-primary' + (errorFound ? ' disabled' : '')"
                    type="submit" v-html="formComponent.submitButtonText ? formComponent.submitButtonText : 'Submit'"></button>
                </div>

                <div class="form-disclaimer text-center">
                  By signing up you agree to our <a href="#privacy-policy" data-bs-toggle="modal" data-bs-target="#privacy-policy-footer">Privacy policy</a>
                </div>                
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style lang="scss">
// Modal styles for form
.modal-body{
  .lead-gen-form-component{
    .lead-gen-form-inner-section{
      border: 0;
    }
  }
}



// General styles for form
.lead-gen-form-component{
  width: 100%;
  padding: 20px 0;

  .btn.submit-btn:disabled {
    background-color: #1F1F1F !important;
    color: #afafaf;
    opacity: 0.65;
    border-color: #1f1f1f !important;
  }
  .social-share-container{
    width: 100%;
    max-width: 420px;
    background: $color-secondary;
    padding: 40px;
    border: solid 1px $color-light-gray;
    border-radius: 24px;
    margin: 0 auto;
  }
  .lead-gen-form-inner-section{
    background: $color-secondary;
    padding: 40px;
    text-align: left;
    border: solid 1px $color-light-gray;
    border-radius: 24px;
    .lead-gen-form-container{
      max-width: 772px;
      margin: 0 auto;
      .lead-gen-form{
        max-width: 420px;
        margin: 0 auto;
      }
    }
  }
}
.lead-gen-form-title {
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
}

.lead-gen-form-subtitle {
  p:last-child{
    margin-bottom: 0;
  }
}

.lead-gen-form-section {
  background-color: $color-secondary;
  color: $color-body-text;
  padding: 50px 0;

  .lead-gen-form-inner-section {
    background: $color-secondary;
    border-radius: 24px;
    padding: 40px 30px;

    .lead-gen-form-container {
      .lead-gen-form {
        .btn[type=submit] {
          margin-top: 10px;
        }
      }
    }
  }
}

.form-error-message {
  padding: 12px 13px;
  display: block;
  text-align: left;
  font-size: $font-size-base;
  border: solid 1px;
  margin-top: 10px;
  margin-bottom: 30px;
  color: red;
}

.checkbox-group {
  .checkbox-container {
    position: relative;
    .form-checkbox {
      width: 100% !important;
    }
    .checkmark{
      position: absolute;
      left: 15px;
      top: 3px;
    }
    .checkbox-label{
      margin-left: 25px;
    }
  }
}

.input-container{
  margin-top: 15px;
  &.select-container{
    select.form-control{
      padding-top: 22px;
      padding-bottom: 10px;
      max-height: 50px;
    }
  }
  &.close-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    .icon-close{
      cursor: pointer;
      color: $color-body-text;
      text-decoration: none;
      font-size: 1rem;
      font-weight: 700;
    }
  }
}

.printed-brochure-group{
  margin-bottom: 30px;
}

.form-extra-disclaimer {
  font-size: $font-size-small;

  a {
    color: $color-body-text;
    font-weight: bold;
  }
}

.submit-button{
  text-align: center;
  margin-top: 40px;
}

.form-disclaimer {
  padding-top: 20px;
  font-size: $font-size-tiny;

  a {
    color: $color-body-text;
    font-weight: bold;
  }
}

</style>